import React from "react";
import { navigate } from "gatsby";
import { isBrowser } from "../components/services/auth";

const IndexPage = () => {
  isBrowser() && navigate('/404');

  return(<div></div>);
};

export default IndexPage;

